.card-grid-header {
	margin-bottom: @spacing-40;

	.heading-2 {
		margin: 0;
	}

	.btn {
		margin-top: @spacing-16;
	}

	@media(min-width: @screen-sm) {
		margin-bottom: @spacing-48;
		display: flex;
		align-items: flex-end;
		justify-content: space-between;

		.btn {
			margin-top: 0;
		}
	}
}

.card-grid {
	background-size: cover;
	background-position: center;
}

.card-grid-container {
	width: calc(~"100% + @{spacing-24}");
	margin: 0 -@spacing-24 * 0.5;

	.slick-list {
		padding-right: @spacing-112;
	}

	@media(min-width: @screen-md) {
		display: flex;
		flex-wrap: wrap;
		margin: -@spacing-24 * 0.5;
	}

	@media(max-width: (@screen-md - 1)) {
		padding-right: 0;
	}
}

.card-grid-item {
	padding: 0 @spacing-24 * 0.5;

	.card-inner {
		position: relative;
		display: block;
	}

	.image {
		width: 100%;
		padding-top: 115%;
		position: relative;

		.heading-5 {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			padding: @spacing-16;
			z-index: 1;
			color: @color-white;
			transition: opacity 0.1s ease;
			transition-delay: 0.3s;

			@media(min-width: @screen-sm) {
				padding: @spacing-24 @spacing-32;
			}
		}
	}

	.color-overlay {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0.8;

		&.hide-mobile {
			display: none;

			@media(min-width: @screen-sm) {
				display: block;
			}
		}

		&.heading-overlay {
			z-index: -1;
			transition: opacity 0.1s ease;
			transition-delay: 0.3s;
		}

		&.card-overlay {
			opacity: 0;
			height: 0;
			transition: all 0.3s cubic-bezier(.59,.68,.44,.94);
		}
	}

	.content {
		margin-top: @spacing-16;

		.heading-5, .btn {
			display: none;
		}

		.heading-5, p {
			margin: 0;
		}

		.heading-5 + p {
			margin-top: 10px;
		}

		p + p {
			margin-top: 8px;
		}
	}

	a:hover {
		text-decoration: none;
	}
	

	@media(min-width: @screen-md) {
		width: 50%;
		padding: @spacing-24 * 0.5;

		&.width-50 {
			width: 100%;

			.image {
				padding-top: 56.25%;
			}
		}

		.content {
			margin-top: 0;
			position: absolute;
			z-index: 1;
			width: 100%;
			height: 100%;
			top: 0;
		    left: 0;
		    padding: @spacing-32;
		    display: flex;
		    flex-direction: column;
		    justify-content: center;
		    opacity: 0;
		    transition: opacity 0.3s cubic-bezier(.59,.68,.44,.94);
		    transition-delay: 0s;

		    .heading-5, .btn {
				display: block;
				margin-top: auto;
			}

		    .btn {
		    	align-self: flex-start;
		    }

			.heading-5, p {
				color: @color-white;
			}
		}

		a:hover {
			.heading-overlay,
			.image .heading-5 {
				opacity: 0;
				transition-delay: 0s;
			}

			.card-overlay {
				opacity: 0.8;
				height: 100%;
			}

			.content {
				opacity: 1;
				transition-delay: 0.3s;
			}
		}
	}

	@media(min-width: 1450px) {
		width: 25%;

		&.width-50 {
			width: 50%;
		}
	}
}

.card-grid-progress-bar {
	display: block;
	width: calc(~"100% - @{spacing-64}");
	height: 4px;
	border-radius: 2px;
	margin: @spacing-40 auto 0 auto;
	overflow: hidden;
	background-color: @color-gray-lighter;
	background-image: linear-gradient(to right, @color-dark-teal, @color-dark-teal);
	background-repeat: no-repeat;
	background-size: 0 100%;
	transition: background-size .3s ease;

	@media(min-width: @screen-md) {
		display: none;
	}
}