﻿@import '../framework/imports.less';

.side-by-side {
    //default is for these components to have margin
    .component-margin();

    &.theme-light {
        background: @color-white;
        color: @color-black;
    }
    //for dark colors we cancel out margin and convert to padding
    &.theme-gray,
    &.theme-dark {
        margin: 0;
        .component-padding();
    }

    &.theme-gray {
        background: @color-gray-lighter;
        color: @color-black;
    }

    &.theme-dark {
        background: @color-black;
        color: @color-white;

        .btn {
            background: @color-white;
            color: @color-black;
        }
    }
    // when two similar themes are stacked padding from top
    &.theme-light:not(.image-bg) + .theme-light:not(.image-bg),
    &.theme-gray:not(.image-bg) + .theme-gray:not(.image-bg),
    &.theme-dark:not(.image-bg) + .theme-dark:not(.image-bg) {
        padding-top: 0;
    }
    // custom theme should always have padding
    &.theme-light + .theme-custom,
    &.theme-dark + .theme-custom,
    &.theme-gray + .theme-custom {
        padding-top: 0;
        padding-bottom: 0;
    }

    &.layout-imagebg-content1,
    &.layout-imagebg-content2,
    &.layout-imagebg-content3 {
        &.theme-light {
            color: @color-white;

            .btn {
                background: @color-white;
                color: @color-black;
            }
        }

        &.theme-dark {
            color: @color-black;

            .btn {
                background: @color-black;
                color: @color-white;
            }
        }
    }
}

.side-by-side.layout-imagebg-content1,
.side-by-side.layout-imagebg-content2,
.side-by-side.layout-imagebg-content3 {
    padding-bottom: 0;
    padding-top: 0;
    position: relative;
    width: 100%;

    .side-by-side-image-wrapper {
        margin-left: -20px;
        margin-right: -20px;
        min-height: 360px;
        z-index: 0;
    }

    .side-by-side-content {
        bottom: 20px;
        left: 0;
        position: absolute;
        right: 0;
        z-index: 1;
    }
}

// flex row, and layout settings
@media (min-width: @screen-sm) {
    .side-by-side .container-large {
        display: flex;
        align-items: center;

        &:before,
        &:after {
            display: none;
        }
    }

    .side-by-side:nth-child(2n + 1) .container-large {
        flex-direction: row-reverse;
    }

    .side-by-side:nth-child(2n) .side-by-side-content-wrapper {
        float: right;
    }

    .side-by-side.layout-left .container-large {
        flex-direction: row;

        .side-by-side-content-wrapper {
            float: right;
        }
    }

    .side-by-side.layout-right .container-large {
        flex-direction: row-reverse;

        .side-by-side-content-wrapper {
            float: left;
        }
    }

    .side-by-side-content-wrapper {
        width: 100%;
    }

    .side-by-side-content,
    .side-by-side-image-wrapper {
        width: 50%;
    }

    .side-by-side.layout-image3-content1 {
        .side-by-side-content {
            width: 33.3333%;
        }

        .side-by-side-image-wrapper {
            width: 66.6666%;
        }
    }

    .side-by-side.layout-image1-content3 {
        .side-by-side-content {
            width: 66.6666%;
        }

        .side-by-side-image-wrapper {
            width: 33.3333%;
        }
    }

    .side-by-side.layout-imagebg-content1,
    .side-by-side.layout-imagebg-content2,
    .side-by-side.layout-imagebg-content3 {
        padding: 0;

        .container-large {
            display: block;
            margin: 0;
            max-width: 100%;
            padding: 0;
        }

        .side-by-side-content {
            .translate(0, -50%);
            bottom: auto;
            margin-left: auto;
            margin-right: auto;
            max-width: 1560px;
            position: absolute;
            top: 50%;
            width: 100vw;

            .side-by-side-heading, .side-by-side-copy, .side-by-side-button-wrapper {
                padding: 0 20px;
            }
        }

        .side-by-side-image-wrapper {
            margin-left: 0px;
            margin-right: 0px;
            min-height: 620px;
            width: 100%;
        }
    }

    .side-by-side.layout-imagebg-content1 {
        .side-by-side-content-wrapper {
            width: 33.3333%;
        }
    }

    .side-by-side.layout-imagebg-content2 {
        .side-by-side-content-wrapper {
            width: 50%;
        }
    }

    .side-by-side.layout-imagebg-content3 {
        .side-by-side-content-wrapper {
            width: 66.6666%;
        }
    }
}

.side-by-side-image-wrapper {
    height: auto;
    .loading();
    cursor: auto;
    overflow: hidden;
}

.side-by-side-image {
    z-index: @mixin-loading-animation-after + 1;
    transition: @transition-base;
    position: relative;
    width: 100%;
}

.side-by-side-content {
    padding-left: 5vw;
    padding-right: 5vw;
}

.side-by-side-heading {
    @media (min-width: @screen-sm) {
        margin-top: 0;
    }
}

.side-by-side-button-wrapper {
    display: flex;
    flex-direction: column;

    @media (min-width: @screen-sm) {
        float: left;

        .text-center & {
            float: none;
            align-items: center;
        }
    }

    .btn + .btn {
        margin-top: @margin-small;
    }
}