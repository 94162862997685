﻿@import '../framework/imports';

@utility-element-padding-mobile: .5em;
@utility-element-padding: .5em .8em;

.utility-nav {
    font-size: @font-size-small;
    color: @color-white;
    background: @color-black;
    text-transform: uppercase;
    position: relative;
    font-weight: @font-weight-bold;
    z-index: @utility-nav-z-index;

    .container {
        position: relative;
        .flex-display;
        padding-top: @utility-navigation-padding;
        padding-bottom: @utility-navigation-padding;
        .align-items(center);
    }

    a {
        color: @color-white;
        border-radius: 3px;
        background-color: @color-black;

        &:hover {
            text-decoration: none;
            color: @color-white;
            background-color: @color-anchor-nav-hover;
        }

        &:focus {
            text-decoration: none;
        }
    }

    @media (min-width: @screen-md) {
        font-size: @font-size-base;
    }
}

.utility-home-link {
    margin-left: -0.4em;
    padding: @utility-element-padding;
    position: relative;

    .evo-logo-inline {
        font-size: @font-size-small;

        @media (min-width: @screen-md) {
            font-size: @font-size-base;
        }
    }

    &:after {
        position: absolute;
        height: 60%;
        border-right: 1px solid @color-white;
        content: "";
        top: 20%;
        right: 0;
    }
}

.utility-nav-title {
    margin-right: @margin-small;
    line-height: 1;
    padding: @utility-element-padding;

    @media(max-width: 410px) {
        display: none;
    }

    @media (min-width: @screen-sm) {
        padding: @utility-element-padding;
    }

    @media (min-width: @screen-lg) {
        margin-right: @margin-medium;
    }
}

.utility-nav-anchor-links {
    display: none;

    @media (min-width: @screen-sm) {
        display: block;
    }
}

.utility-nav-list {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
        display: inline-block;
        margin: 0 1px;
    }

    a {
        padding: @utility-element-padding-mobile;
        font-weight: @font-weight-bold;

        @media (min-width: @screen-lg) {
            padding: @utility-element-padding;
        }

        &.active {
            background-color: @color-anchor-nav-active !important;
        }
    }
}