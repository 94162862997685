﻿@import '../framework/imports.less';

.component-product-container {
    .component-margin();
    padding-left: 0;
    padding-right: 0;
    text-align: center;

    & .row {
        margin-left: 0;
        margin-right: 0;
    }

    .component-header {
        margin-top: 0;
    }

    .results-products {
        padding: 0;
        border: 0;
    }
}

.product-snippet-row {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    text-align: center;
    width: 100%;
    white-space: nowrap;

    .product-thumb {
        display: inline-block;
        float: none;
        min-width: 180px;
        vertical-align: top;
        width: 20%;
    }
}