﻿@import '../../framework/imports.less';

.index-landing {
    .js-loading-container.loading {
        min-height: 600px;
    }

    .js-content-container {
        display: none;
    }

    .content-tile-container {
        padding: @component-margin-base 0;
    }
}

.guide-header-container {
    position: relative;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    padding: @margin-base @grid-gutter-width;

    @media (min-width: @screen-sm) {
        padding: @margin-large @grid-gutter-width;
    }

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255,255,255,0.9);
        z-index: 0;
    }
}

.guide-header-content {
    position: relative;
    z-index: 1;
    max-width: 680px;
    margin: 0 auto;
}

.guide-header {
    font-size: 26px;
    margin-top: 0;
    text-align: center;

    @media (min-width: @screen-sm) {
        font-size: 34px;
        margin-top: 0;
    }

    @media (min-width: @screen-lg) {
        font-size: 42px;
        margin-top: 0;
    }

    span {
        display: inline-block;
        font-size: 1.0em;
        position: relative;
        vertical-align: bottom;
    }
}

.guide-intro {
    margin-bottom: 0;
}

.index-search-bar {
    background-color: @color-white;
    padding: @margin-medium;
    .flex-display();
    .justify-content(center);
    .align-items(center);
    .flex-wrap(wrap);
}

.guide-home-link {
    margin-bottom: @component-margin-small;
    width: 100%;
    text-align: center;
    cursor: pointer;

    @media (min-width: @screen-md) {
        width: auto;
        margin-bottom: 0;
        margin-right: @margin-base;
    }
}

.index-search-wrapper {
    position: relative;
    padding-left: 50px;
    width: 100%;
    max-width: 630px;
}

.index-question-mark {
    width: 40px;
    position: absolute;
    top: -12px;
    left: 0;
}

.index-search-container {
    width: 100%;
}

.index-utilities-container {
    .flex-display();
    .justify-content(center);
    .align-items(center);
    .flex-wrap(wrap);
    padding: 0 @grid-gutter-width !important;
    max-width: inherit;
}

.index-subhead-container {
    position: relative;
    margin: @margin-small 0;
    padding: 0 @grid-gutter-width;
    padding-top: @grid-gutter-width;
    display: inline-block;
    text-align: center !important;
}

.index-subheader {
    margin-top: 0;
    width: 100%;
    .transition(@transition-base);
}

.index-button-row {
    margin-top: @component-margin-small;
}

.index-list-wrapper {
    padding: @margin-small 0 @component-margin-base 0;
}

.index-list-container {
    margin-top: @component-margin-base;

    @media (min-width: @screen-xs) {
        column-count: 2;
    }

    @media (min-width: @screen-md) {
        column-count: 3;
    }
}

.index-list-title {
    text-align: center;
    margin-top: 0;
}

.guide-list {
    padding: 0 @margin-small;
    margin-bottom: @margin-medium;
    break-inside: avoid;

    ul {
        list-style: none;
        padding-left: 0;
    }
}

.guide-list-header {
    border-bottom: 2px solid @color-gray-light;
    padding-bottom: @margin-xsmall;
    margin-top: 0;
}

.guide-list-item {
    padding: @margin-xsmall 0;
}

.button-loading {
    .loading();
    color: rgba(255,255,255, 0.5) !important;

    &:before {
        display: none;
    }

    &:after {
        border-width: 2px;
        height: 18px;
        width: 18px;
        margin-top: -9px;
        margin-left: -9px;
    }
}

.sticky-container {
    margin-bottom: 0;
    margin-top: 0;
    padding-bottom: 0;
    z-index: 2;
}

.masthead-index-search-clear {
    display: block;
    right: 0;
}