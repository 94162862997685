.split-callout-container {
	& + .split-callout-container {
		margin-top: @spacing-48;
	}

	.html-column + .html-column {
		margin-top: @spacing-24;
	}

	@media(min-width: @screen-sm) {
		align-items: center;

		& + .split-callout-container {
			margin-top: @spacing-112;
		}

		&:nth-child(even) {
			.split-callout-image:not(.image-align-left) {
				order: 2;
			}
		}

		.html-column + .html-column {
			margin-top: 0;
		}

		&.layout-onethird-twothirds .html-column {
			&:first-child {
				width: 40%;
			}

			&:last-child {
				width: 60%;
			}
		}

		&.layout-twothirds-onethird .html-column {
			&:first-child {
				width: 60%;
			}

			&:last-child {
				width: 40%;
			}
		}
	}
}

.split-callout-image {
	.image-wrapper {
		position: relative;
		width: 100%;
		padding-top: 100%;
	}

	@media (min-width: @screen-sm) {
		&.image-align-left {
			order: -1;
		}

		&.image-align-right {
			order: 2;
		}

		.image-wrapper {
			padding-top: 56.25%;
		}
	}
}

.split-callout-content {
	.btn-group {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		margin: -@spacing-16;
		max-width: 400px;

		.btn {
			margin: @spacing-16;
		}
	}

	&.text-center .btn-group {
		margin: -@spacing-16 auto;
		justify-content: center;
	}
}