﻿@import '../framework/imports.less';

@gallery-hover-background: rgba(0,0,0,0.5);
@gallery-video-play-button-width: 60px;

.video-component {
    position: relative;
    cursor: pointer;
    margin-bottom: @grid-gutter-width;

    @media (min-width: @screen-sm) {
        margin-bottom: 0;
    }

    &:hover {
        .video-play-button {
            background-color: @color-black-transparent;
        }
    }

    &.playing {
        .video-play-button {
            opacity: 0;
        }

        .video-content {
            opacity: 1;
        }
    }

    .video-play-button {
        position: absolute;
        .flex-display;
        width: @gallery-video-play-button-width;
        height: @gallery-video-play-button-width;
        background-color: @gallery-hover-background;
        color: @color-white;
        top: 50%;
        left: 50%;
        margin-top: -@gallery-video-play-button-width/2;
        margin-left: -@gallery-video-play-button-width/2;
        .justify-content(center);
        .align-items(center);
        font-size: @font-size-large;
        border-radius: 50%;
        opacity: 1;
        .transition(all 0.3s ease-out);

        span {
            margin-left: 0.2em;
        }
    }

    .video-image {
        width: 100%;
    }
}

.video-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    .transition(opacity 0.3s ease-out);
}

.video-source {
    width: 100%;
    height: 100%;
}