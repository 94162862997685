﻿@import '../framework/imports.less';

.slick-carousel {
    .slick-next, .slick-prev {
        .global-arrow-mixin();
    }

    .slick-prev:not([dir="rtl"]) {
        .global-previous-arrow();
        left: 10px;
    }

    .slick-prev {
        [dir="rtl"] & {
            right: 10px;
        }
    }

    .slick-next:not([dir="rtl"]) {
        right: 10px;
    }

    .slick-next {
        [dir="rtl"] & {
            .global-previous-arrow();
            left: 10px;
        }
    }

    .slick-slide-img {
        width: 100%;
    }

    .slick-dots {
        bottom: 20px;

        li {
            height: 14px;
            width: 14px;
            margin: 0 6px;
        }

        li button {
            border: 1px solid white;
            background: transparent;
            border-radius: 50%;
            box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 1);
            height: 14px;
            width: 14px;
        }

        li button[aria-selected="true"] {
            background: white;
        }
    }

    .slick-play-pause {
        position: absolute;
        bottom: 20px;
        z-index: 1;
        border: none;
        background: rgba(0,0,0,0.5);
        border-radius: 3px;
        color: white;
    }

    .responsive-banner-buttons {
        display: none;

        .slick-active & {
            display: block;
        }
    }

    .slick-active {
        .responsive-banner-buttons {
            display: block;
        }
    }

    .btn-info {
        background-color: white;

        &:hover {
            background-color: #ddd;
        }
    }
    //Use pseudo elements to show whitespace placeholder and prevent CLS
    @media (min-width: 768px) {
        &::before {
            padding-top: 583px / 1600px * 100%;
        }
    }

    @media (max-width: 767px) {
        &::before {
            padding-top: 583px / 768px * 100%;
        }
    }

    &::before {
        content: "";
        width: 1px;
        margin-left: -1px;
        float: left;
        height: 0;
    }

    &::after { //Clear float
        content: "";
        display: table;
        clear: both;
    }
}