﻿@import '../framework/imports.less';

@gallery-hover-background: rgba(0,0,0,0.5);
@gallery-image-max-width-xs: 240px;
@gallery-image-max-width-sm: 320px;

.gallery-container {
    font-size: 0;
    margin: 0 auto @grid-gutter-width auto;
    max-width: @container-large-desktop;
    overflow-x: auto;
    padding: 0 @grid-gutter-width/2;
    position: relative;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    //At mobile when a see-click-buy tool tip is active, overflow-x needs to be
    //set to initial to allow for the tooltip to be visible beyond the bounds of the
    //gallery-container.  The left position of the container is preserved via JS is responsive-banner.js
    .see-click-buy-active & {
        overflow-x: initial;
    }

    @media (min-width: @screen-sm) {
        .flex-display;
        .flex-flow(row nowrap);
        overflow: initial;
    }

    @media (min-width: @screen-lg) {
        padding: 0;
    }
}

.gallery-element {
    display: inline-block;
    margin: 0 @margin-small;
    position: relative;
    white-space: normal;

    .gallery-image {
        @media (max-width: @screen-xs-max) {
            max-width: @gallery-image-max-width-sm;
        }

        @media (max-width: @screen-xs) {
            max-width: @gallery-image-max-width-xs;
        }
    }

    &:only-child {
        .gallery-image {
            max-width: 100%;
        }
    }
}

.no-touch .gallery-element:hover .gallery-hover-caption {
    opacity: 1;
}

.gallery-hover-caption {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 100%;
    height: 100%;
    width: 100%;
    background-color: @gallery-hover-background;
    .flex-display;
    .flex-direction(column);
    text-align: center;
    .align-items(center);
    .justify-content(center);
    opacity: 0;
    font-size: @font-size-base;
    .transition(opacity 0.3s ease-out);

    @media (min-width: @screen-md) {
        font-size: @font-size-base + 4;
    }

    .gallery-zoom-icon {
        background-image: url('data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0D%0A%3C%21--%20Generator%3A%20Adobe%20Illustrator%2018.1.1%2C%20SVG%20Export%20Plug-In%20.%20SVG%20Version%3A%206.00%20Build%200%29%20%20--%3E%0D%0A%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20x%3D%220px%22%20y%3D%220px%22%0D%0A%09%20viewBox%3D%220%200%2042%2042%22%20enable-background%3D%22new%200%200%2042%2042%22%20xml%3Aspace%3D%22preserve%22%3E%0D%0A%3Cg%3E%0D%0A%09%3Ccircle%20fill%3D%22none%22%20stroke%3D%22%23FFFFFF%22%20stroke-width%3D%222%22%20stroke-miterlimit%3D%2210%22%20cx%3D%2221%22%20cy%3D%2221%22%20r%3D%2220%22/%3E%0D%0A%09%3Cg%3E%0D%0A%09%09%3Cpolyline%20fill%3D%22none%22%20stroke%3D%22%23FFFFFF%22%20stroke-width%3D%222%22%20stroke-miterlimit%3D%2210%22%20points%3D%2217.9%2C10.1%2031.9%2C10.1%2031.9%2C24.1%20%09%09%22/%3E%0D%0A%09%09%3Cpolyline%20fill%3D%22none%22%20stroke%3D%22%23FFFFFF%22%20stroke-width%3D%222%22%20stroke-miterlimit%3D%2210%22%20points%3D%2224.1%2C31.9%2010.1%2C31.9%2010.1%2C17.9%20%09%09%22/%3E%0D%0A%09%09%3Cline%20fill%3D%22none%22%20stroke%3D%22%23FFFFFF%22%20stroke-width%3D%222%22%20stroke-miterlimit%3D%2210%22%20x1%3D%2231.9%22%20y1%3D%2210.1%22%20x2%3D%2210.1%22%20y2%3D%2231.9%22/%3E%0D%0A%09%3C/g%3E%0D%0A%3C/g%3E%0D%0A%3C/svg%3E%0D%0A');
        background-repeat: no-repeat;
        width: 40px;
        height: 40px;
        margin-bottom: 1em;
    }
}

.gallery-hover-caption-text {
    color: #fff;
    padding: 1em;
}