.location-map {
	text-align: center;
}

.location-map-heading {
	margin-bottom: @spacing-48;

	@media (min-width: @screen-md) {
		margin-bottom: @spacing-80;
	}
}

.location-map-info {
	.location-details, .hours-details {
		margin-bottom: @spacing-48;
	}

	.location-details .location-cta-buttons {
		margin-top: @spacing-16;
	}

	.address-link {
		display: block;
	}

	@media (min-width: @screen-md) {
		display: flex;
		align-items: center;
		justify-content: center;

		.location-details {
			width: 25%;
			padding-right: @spacing-64;
			margin-bottom: 0;
		}

		.hours-details {
			width: 25%;
			padding-left: @spacing-64;
			margin-bottom: 0;
			order: 3;
		}

		.location-google-map {
			width: 50%;
		}

		.address-link {
			margin-bottom: @spacing-16;
		}

		.info-divider {
			margin: 0 @spacing-8;
		}
	}
}

.location-icon {
	font-size: 32px;
	float: none;
	padding: 0;
	margin: 0 auto @spacing-16 auto;
	display: block;
}

.location-heading {
	& + p, & + a {
		margin-top: @spacing-16;
	}
}

.location-google-map {
	width: 100%;

	.map-wrapper {
		padding-top: 100%;
		position: relative;
		background-color: #fff;

		@media (min-width: @screen-md) {
			padding-top: 48%;
		}
	}

	.google-map-container {
		position: absolute;
	    width: 100%;
	    height: 100%;
	    top: 0;
	    left: 0;
	}
}

.location-cta-buttons {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	margin-top: @spacing-40;

	.btn {
		margin: @spacing-8;
	}

	@media (min-width: @screen-md) {
		margin-top: @spacing-72;

		.btn {
			margin: @spacing-20;
		}
	}
}