.card-collection-header {
	margin-bottom: @spacing-40;

	.heading-2 {
		margin: 0;
	}

	.btn {
		margin-top: @spacing-16;
	}

	@media(min-width: @screen-sm) {
		margin-bottom: @spacing-48;
		display: flex;
		align-items: flex-end;
		justify-content: space-between;

		.btn {
			margin-top: 0;
		}
	}
}

.card-collection {
	background-size: cover;
	background-position: center;
}

.card-collection-grid {
	@media(min-width: @screen-xs) {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: @spacing-32;
	}

	@media(min-width: @screen-sm) {
		grid-template-columns: repeat(3, 1fr);
	}

	@media(min-width: @screen-lg) {
		grid-template-columns: repeat(4, 1fr);
	}
}

.card-collection-item {

	& + .card-collection-item {
		margin-top: @spacing-24;
	}

	.image {
		width: 100%;
		padding-top: 130.9%;
		background-color: @color-dark-teal;
		margin-bottom: @spacing-24;
		position: relative;
		overflow: hidden;

		img {
			transition: all 0.6s ease;
		}

		.color-overlay {
			position: absolute;
			z-index: 1;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			transition: opacity 0.3s ease;

			& + img {
				filter: grayscale(1);
			}
		}

		a:hover {
			img {
				transform: scale(1.1);
			}

			.color-overlay {
				opacity: 0 !important;

				& + img {
					filter: grayscale(0);
				}
			}
		}
	}

	.content {
		padding: 0 @spacing-16;

		.heading-5 + .sub-heading {
			margin-top: @margin-small;
		}

		.paragraph-regular {
			margin-bottom: 14px;
		}

		@media(min-width: @screen-lg) {
			padding: 0 @spacing-32;

			.paragraph-regular {
				margin-bottom: @spacing-16;
			}
		}
	}

	@media(min-width: @screen-xs) {
		& + .card-collection-item {
			margin-top: 0;
		}
	}
}

.card-collection-grid.card-orientation-landscape {
	.card-collection-item .image {
		padding-top: 100%;
	}

	@media(min-width: @screen-xs) {
		grid-template-columns: repeat(1, 1fr);
	}

	@media(min-width: @screen-sm) {
		grid-template-columns: repeat(2, 1fr);

		.card-collection-item .image {
			padding-top: 56.25%;
		}
	}
}

.card-collection-slider {
	display: block;
	padding-right: 0;
	width: calc(~"100% + @{spacing-24}");
	margin: -@spacing-24 * 0.5;

	.slick-list {
		padding-right: @spacing-112;
	}

	.card-collection-item {
		padding: 0 @spacing-24 * 0.5;

		& + .card-collection-item {
			margin-top: 0;
		}
	}

	.slick-next, .slick-prev {
		width: 48px;
		height: 48px;
		background-color: @color-white;
		border-radius: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: all 0.3s ease;
		z-index: 1;

		&:before {
			color: @color-black;
			opacity: 1;
			.icon-mixin();
			font-size: 24px;
			transition: all 0.3s ease;
		}

		&:hover {
			background-color: @color-dark-teal;

			&:before {
				color: @color-white;
			}
		}

		&.slick-disabled {
			display: none !important;
		}
	}

	.slick-prev {
		left: calc(~"@{gutter} * 3 + @{spacing-32}");

		&:before {
			content: "\e60b";
		}
	}

	.slick-next {
		right: @spacing-32;

		&:before {
			content: "\e610";
		}
	}

	@media(min-width: @screen-sm) {
		width: calc(~"100% + @{spacing-32}");
		margin: -@spacing-16;

		.slick-list {
			padding-right: @gutter * 7;
		}

		&.card-orientation-landscape .slick-list {
			padding-right: @gutter * 10;
		}

		.card-collection-item {
			padding: 0 @spacing-16;
		}
	}

	@media(min-width: @screen-md) {
		.slick-next, .slick-prev {
			width: 64px;
			height: 64px;

			&:before {
				font-size: 32px;
			}
		}

		.slick-prev {
			left: calc(~"@{gutter} * 3 + @{spacing-48}");
		}

		.slick-next {
			right: @spacing-48;
		}
	}
}

.card-collection-progress-bar {
	display: block;
	width: calc(~"100% - @{spacing-64}");
	height: 4px;
	border-radius: 2px;
	margin: @spacing-40 auto 0 auto;
	overflow: hidden;
	background-color: @color-gray-lighter;
	background-image: linear-gradient(to right, @color-dark-teal, @color-dark-teal);
	background-repeat: no-repeat;
	background-size: 0 100%;
	transition: background-size .3s ease;

	@media(min-width: @screen-sm) {
		display: none;
	}
}