.ambassador-profile-wrap {
	.ambassador-bio {
		margin-bottom: @spacing-32;

		p {
			.paragraph-regular();
		}
	}
	
	@media(min-width: @screen-sm) {
		display: flex;

		.ambassador-bio {
			width: 50%;
			margin-bottom: 0;
		}

		.ambassador-links {
			width: 50%;
			padding-left: @gutter * 3;
		}
	}
}

.ambassador-link-section{

	& + .ambassador-link-section {
		margin-top: @spacing-16;
	}

	.heading-5 {
		margin-bottom: @spacing-16;
	}

	.social-icon {
		text-decoration: none;
		width: 40px;
		height: 40px;
		border: 1px solid @color-gray-light;
		border-radius: 100%;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		color: @color-black;
		transition: color 0.3s ease;
		margin: 0 @spacing-16 @spacing-16 0;
		font-size: 20px;

		&:hover {
			color: @color-dark-teal;
		}
	}

	.ambassador-logo {
		text-decoration: none;
		display: inline-block;
		margin: 0 @spacing-16 @spacing-16 0;
		max-width: 50%
	}

	@media(min-width: @screen-sm) {
		& + .ambassador-link-section {
			margin-top: @spacing-16 * 0.5;
		}

		.heading-4 {
			margin-bottom: @spacing-24;

			&.sponsors-heading {
				margin-bottom: @spacing-16;
			}
		}

		.social-icon {
			width: 65px;
			height: 65px;
			font-size: 30px;
			margin: 0 @spacing-24 @spacing-24 0;
		}

		.ambassador-logo {
			margin: 0 @spacing-24 @spacing-24 0;
		}
	}
}