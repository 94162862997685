.impact-statisics-intro {
    margin-bottom: 0;

    .html-column {
        width: 1000px;
        max-width: 100%;
        margin: 0 auto;
    }
}

.impact-statistics .multi-column-container {
    .heading-3 + .paragraph-regular {
        width: 390px;
        max-width: 100%;
    }
    
    @media(min-width: @screen-sm) {
        width: calc(~"100% + @{gutter} * 4");
        margin: -@gutter * 2;

        .html-column {
            padding: @gutter * 2;
        }

        & + .multi-column-container {
            margin-top: calc(~"@{spacing-64} - @{gutter} * 2");
        }

        &.layout-onethird-onethird-onethird .html-column {
            width: 50%;

            &:last-child {
                width: 100%;

                .number-stats {
                    display: flex;
                    gap: @spacing-24;

                    .number + .number {
                        margin-top: 0;
                    }
                }
            }
        }
    }

    @media(min-width: @screen-lg) {
        &.layout-onethird-onethird-onethird .html-column {
            width: 33.33%;

            &:last-child {
                width: 33.33%;

                .number-stats {
                    display: block;

                    .number + .number {
                        margin-top: inherit;
                    }
                }
            }
        }
    }
}

.pie-chart-container, .number-stats {
    margin-top: @spacing-24;

    @media(min-width: @screen-sm) {
        margin-top: @spacing-32;
    }
}

.pie-chart-percent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 64px;
    font-weight: bold;
    transition: opacity 0.3s ease;

    sup {
        font-size: 32px;
    }

    @media(min-width: @screen-lg) {
        font-size: 112px;

        sup {
            font-size: 64px;
        }
    }
}

.pie-chart-wrapper {
    position: relative;
}

.pie-chart-legend {
    list-style-type: none;
    padding: 0;
    margin-top: @spacing-32;
  
    li {
        .paragraph-regular();
        display: block;
        position: relative;
        padding-left: 36px;
    
        i {
            content: '';
            width: 24px;
            height: 24px;
            display: inline-block;
            background-color: @color-tomato;
            border-radius: 100%;
            position: absolute;
            top: 0.1em;
            left: 0;
        }
    
        & + li {
            margin-top: 12px;
        }
    }
}

.number-stats .number {
    .h3 {
        color: @color-tomato;
        font-size: 64px;
        margin: 0;
        white-space: nowrap;

        &.count-wrap {
            opacity: 0;
            transition: opacity 0.2s ease;

            &.fade-in {
                opacity: 1;
            }
        }

        & + p {
            width: 320px;
            max-width: 100%;
        }

        sup {
            font-size: 32px;

            &.align-middle {
                vertical-align: middle;
                top: 0;
            }
        }

        @media(min-width: 1600px) {
            font-size: 112px;

            sup {
                font-size: 64px;
                top: -0.4em;
            }
        }
    }

    & + .number {
        margin-top: @spacing-24;

        @media(min-width: @screen-sm) {
            margin-top: @spacing-32;
        }
    }

    p {
        margin: 0;
    }

    .odometer.odometer-auto-theme, .odometer.odometer-theme-default {
        line-height: 0.9em;

        & {
            font-family: @font-family-loaded;
            font-weight: 800;
        }
    }

    .odometer-value {
        text-align: center;
        &.odometer-first-value, &.odometer-last-value {
            width: 100%;
        }
    }

    .odometer.odometer-animating-up .odometer-ribbon-inner, .odometer.odometer-animating-down.odometer-animating .odometer-ribbon-inner {
        -webkit-transition-duration: 3s !important;
        -moz-transition-duration: 3s !important;
        -ms-transition-duration: 3s !important;
        -o-transition-duration: 3s !important;
        transition-duration: 3s !important
    }
}

.impact-statisics-cta {
    width: 1200px;
    max-width: 100%;
    margin: 0 auto;

    p {
        margin: 0 0 @spacing-24 0;
    }

    @media(min-width: @screen-md) {
        display: flex;
        align-items: center;

        p {
            flex: 1 1 auto;
            margin: 0;
        }

        .btn {
            flex: 0 0 auto;
            margin-left: @gutter * 3;
        }
    }
}