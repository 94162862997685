.detail-intro {
	.intro-icon {
		width: 112px;
		margin: 0 auto;

		.icon-evo-text {
			vertical-align: middle;
		}

		p {
			margin: 0;
			font-size: @font-size-small;
			margin-bottom: @spacing-24;
		}

		@media (min-width: @screen-sm) {
			width: 132px;

			p {
				font-size: @font-size-base;
				margin-bottom: @spacing-32;
			}
		}
	}

	.paragraph-regular {
		margin-top: @spacing-16 * 0.5;
		margin-bottom: 0;
	}
}

.detail-intro-img {
	margin-top: @spacing-32;

	.image-container {
		position: relative;
		padding-top: 60.1%;
	}

	@media (max-width: @screen-sm) {
		margin-top: @spacing-48;
		padding: 0;

		.image-container {
			padding-top: 100%;
		}
	}
}