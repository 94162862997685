﻿@import '../framework/imports.less';

@content-tile-spacing: @component-margin-small;

.hero-banner + .content-tile-container {
    //Hate doing this but we may need a more complete view of margins in the near future. 25px is set in CSS from flexslider.
    margin-top: -25px;
}

.content-tile-container {
    .component-margin();
    text-align: center;
    //preventing clearfix for ipad display issues
    &:before, &:after {
        display: none;
    }

    .row {
        margin: (@margin-small * -1);
        .flex-display();
        .justify-content(center);
        .flex-wrap(wrap);
    }

    @media (min-width: @screen-sm) {
        //.flex-wrap(nowrap);
        .align-items(stretch);
    }

    @media (min-width: @screen-lg) {
        padding-left: 0;
        padding-right: 0;
    }
}

.content-tile {
    padding: @margin-small;
    width: 100%;

    h4 {
        margin-top: 0;
    }

    @media (min-width: @screen-xs) and (max-width: @screen-xs-max) {
        width: 80%;
    }

    @media (min-width: @screen-sm) {
        width: 50%;
    }

    @media (min-width: @screen-md) {
        width: 33.33333%;
    }
}

.content-tile-link {
    position: relative;
    background-color: @color-white;
    display: block;
    border: @border-light;
    height: 100%;

    &:hover, &:active, &:focus {
        text-decoration: none;
        color: @color-black;
    }
}

.content-tile-wrapper {
    height: 100%;
}

.no-touch .content-tile-link {
    .hovergrow();
}

.content-tile-subtitle {
    text-transform: uppercase;
    color: @color-gray-dark;
    font-size: 13px;
    display: block;
    margin: .5em 0;
}

.content-tile-image-wrapper {
    position: relative;
    width: 100%;
    overflow: hidden;

    & > .content-tag {
        background-color: @color-green-dark;
        color: @color-white;
        font-weight: @badge-font-weight;
        padding: .2em @content-tile-spacing;
        position: absolute;
        text-transform: uppercase;
        bottom: 10px;
        left: 0;
    }

    & > img {
        width: 100%;
    }

    & > .content-title {
        font-size: 30px;
        left: 30px;
        position: absolute;
        text-align: center;
        top: 10px;
    }
}

.content-tile-content {
    padding: @content-tile-spacing;
    text-decoration: none;
    color: @color-black;
    text-align: left;

    p {
        overflow: hidden;

        &:last-child {
            margin-bottom: 0;
        }
    }
}