﻿@import '../framework/imports';

.component-logos {
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 0;
    .component-margin();

    @media (min-width: @screen-sm) {
        padding-left: 3vw;
        padding-right: 3vw;
    }

    & > section {
        margin-top: @margin-medium * -1;
    }
}

.component-logo-item {
    padding: @margin-medium 2px 0 2px;
    display: inline-block;
    width: 33%;

    @media (min-width: @screen-sm) {
        padding: @margin-medium 10px 0 10px;
        width: auto;
    }

    img {
        width: 106px;
        height: 40px;

        @media (min-width: @screen-sm) {
            width: 120px;
            height: 45px;
        }

        @media (min-width: @screen-lg) {
            width: 180px;
            height: 68px;
        }
    }
}