﻿@import '../framework/imports.less';

.content-utilities {
    background: #000;
    text-transform: uppercase;
    color: #fff;
    padding-top: 1em;
    padding-bottom: 1em;
    line-height: 1em;
    overflow: hidden;
    text-align: center;
    font-size: @font-size-base;
    z-index: @z-index-10;

    @media (min-width: @screen-sm-min) {
        text-align: left;
    }

    .container {
        padding-left: calc((@grid-gutter-width/2) ~"- .6em");
        padding-right: calc((@grid-gutter-width/2) ~"- .6em");
    }

    span,
    a {
        vertical-align: middle;
        display: inline-block;
    }

    a {
        color: #fff;
        display: inline-block;
        border-radius: 2px;
        background: rgba(255,255,255,.12);
        .transition(@transition-base);

        &:hover, &:active, &:focus {
            color: #fff;
            background: rgba(255,255,255,.16);
            text-decoration: none;
        }
    }

    h1 {
        @media (min-width: @screen-sm-min) {
            display: inline-block;
            vertical-align: middle;
        }
    }
}

.content-utility-label {
    text-transform: uppercase;
    margin: 0 0 @margin-small 0;
    width: 100%;

    @media (min-width: @screen-xs) {
        width: auto;
        margin: 0 @margin-small 0 0;
    }
}

.touch .content-utilities-link {
    background: rgba(255,255,255,.16);
}

.touch .content-utilities-link {
    background: rgba(255,255,255,.16);
}

.content-utilities-link {
    color: #fff;
    display: inline-block;
    border-radius: 2px;
    padding: .6em .8em;
    font-weight: bold;
    background: rgba(255,255,255,.12);
    cursor: pointer;
    margin: 2px;
    .transition(@transition-base);

    &:hover, &:active, &:focus {
        color: #fff;
        background: rgba(255,255,255,.16);
    }

    &.active {
        background-color: @color-green-dark;

        &:hover, &:active, &:focus {
            background-color: @color-green-dark;
        }
    }
}

.content-utilities-home {
}

.content-utilities-span {
    padding: .4em .6em;
    display: inline-block;
    line-height: 1.2em;
}

.content-utilities-title {
    font-weight: normal;
    margin: 0;
    font-size: @font-size-base;
    display: block;

    @media (min-width: @screen-xs-min) {
        display: inline-block;
        vertical-align: middle;
    }
}

.content-utilities-divider {
    height: 1.6em;
    top: 0;
    border-right: 1px solid #fff;
    margin-left: .3em;
    margin-right: .3em;

    @media (min-width: @screen-sm-min) {
        height: 2.2em;
    }
}

.content-utilities-icons {
    text-align: center;

    @media (min-width: @screen-sm-min) {
        float: right;
    }

    a {
        line-height: 1.8em;
        padding: 0 .4em;
        font-size: 16px;
    }

    .modal-dialog & {
        display: none;
    }
}