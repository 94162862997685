@import '../framework/imports.less';

.breadcrumb-nav {
	background-color: @color-dark-teal;
	overflow: scroll;
	-ms-overflow-style: none;
  	scrollbar-width: none;

  	&::-webkit-scrollbar {
	  display: none;
	}

	ul {
		list-style-type: none;
		padding: 0;
		margin: 0;
	}

	.breadcrumb {
		white-space: nowrap;

		ul {
			display: flex;
		}
	}

	.sub-navigation {
		display: none;
	}

	.container-full {
		padding: 0 16px;
	}

	@media(min-width: @screen-md) {
		padding: @component-margin-small 0 @component-margin-small @margin-small;

		.container-full {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0 @gutter;
		}

		.breadcrumb, .sub-navigation {
			flex: 1 0 auto;
		}

		.sub-navigation {
			text-align: right;
			display: block;
			padding-right: @margin-medium;
		}
	}
}

.breadcrumb-item, .sub-nav-item {
	color: @color-white;
	font-size: @font-size-small;
	line-height: 1.143em;
	display: inline-block;

	a {
		color: @color-white;

		&:hover, &:focus {
			text-decoration: underline;
		}
	}

	&.current-item {
		font-weight: @headings-font-weight;
	}

	@media(min-width: @screen-md) {
		font-size: @font-size-base;
	}
}

.breadcrumb-item {
	padding: @component-margin-small 0;

	&:not(:last-child):after {
		content: '/';
		display: inline;
		margin: 0 5px;
	}

	&:last-child {
		padding-right: @margin-medium;
	}

	&.breadcrumb-back {
		display: inline-flex;
		align-items: center;

		span {
			font-size: @font-size-small;
			margin-right: @margin-small;
		}

		&:after {
			content: none;
		}

		& + .breadcrumb-item {
			box-shadow: inset 3px 0px 3px 0px rgba(0, 0, 0, 0.26);
			padding-left: @margin-medium;
			margin-left: @margin-medium;
		}
	}

	@media(min-width: @screen-md) {
		padding: 0;

		&.breadcrumb-back:after {
			content: '|';
			margin: 0 14px 0 16px;
		}

		&.breadcrumb-back + .breadcrumb-item {
			box-shadow: none;
			padding-left: 0;
			margin-left: 0;
		}
	}
}

.sub-nav-item + .sub-nav-item {
	margin-left: @padding-small-horizontal;

	@media(min-width: @screen-xl-min) {
		margin-left: @padding-small-horizontal * 2;
	}
}