﻿@import '../framework/imports.less';

.global-arrow {
    .global-arrow-mixin();
}

&.prev-arrow {
    .global-previous-arrow();
}

&.next-arrow {
    //next is default
}