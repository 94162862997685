@import '../framework/imports.less';

/* simple hero banner with text and clickable background */
.hero-banner-simple-with-clickable-bg {
    position: relative;
    display: flex;
    min-height: 400px;
    align-items: center;
    .container-full();
    padding-top: @spacing-48;
    padding-bottom: @spacing-48;

    .hero-img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin-bottom: 0;
        #gradient > .vertical(#ccc, lighten(#ccc, 10%));
        object-fit: cover;
        object-position: center;
    }

    &.container-regular {
        .container-regular();
        padding-top: @spacing-48;
        padding-bottom: @spacing-48;
    }

    .icon-evo-text {
        font-size: 1em;
        display: block;
        margin-bottom: 0;
    }

    .hero-link {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: transparent;
    }

    .hero-video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        z-index: 1;
    }

    .btn-playpause {
        appearance: none;
        -webkit-appearance: none;
        color: @color-white;
        background: transparent;
        border: none;
        font-size: 30px;
        padding: 20px;
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 2;

        &.playing .icon-play:before {
            content: '';
            width: 5px;
            height: 20px;
            display: inline-block;
            background: @color-white;
        }

        &.playing .icon-play:after {
            content: '';
            width: 5px;
            height: 20px;
            display: inline-block;
            background: white;
            margin-left: 5px;
        }
    }

    .hero-overlay {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .hero-icon {
        width: 65px;
        margin-bottom: @margin-medium;

        svg {
            margin: -15px 0;
        }

        &.icon-locations svg {
            margin: -10px;
        }
    }

    .hero-content {
        position: relative;
        width: 100%;

        a {
            position: relative;
            z-index: 1;
            border-radius: 30px;
            margin-top: 16px;
            @media (min-width: @screen-sm) {
                margin-top: 35px;
            }
        }

        .hero-banner-subheading {
            font-weight: 800;
            margin: 16px 0 0 0;
            line-height: 1.75;
            max-width: 50ch;
        }

        &.thought-bubble {
            background-color: @color-white;
            padding: @margin-base;
            align-self: flex-end;
            border-radius: @border-radius-lg;
            border-bottom-right-radius: 0;
        }

        &.align-right {
            margin-left: auto;

            &.thought-bubble {
                border-radius: @border-radius-lg;
                border-bottom-left-radius: 0;
            }
        }

        &.align-center {
            margin: 0 auto;
            border-radius: @border-radius-lg;
            text-align: center;

            .hero-icon {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    @media (min-width: @screen-sm) {
        padding-top: @gutter * 5;
        padding-bottom: @gutter * 5;
        min-height: 530px;

        &.container-regular {
            padding-top: @gutter * 5;
            padding-bottom: @gutter * 5;
        }

        .icon-evo-text {
            font-size: 0.8em;
            vertical-align: bottom;
            display: inline-block;
            margin-right: @padding-base-horizontal;
        }

        .hero-icon {
            width: 130px;
            margin-bottom: @component-margin-base;

            svg {
                margin: -32px 0;
            }

            &.icon-impact {
                width: 120px;
            }

            &.icon-locations {
                width: 120px;

                svg {
                    margin: -20px;
                }
            }

            &.icon-academy {
                width: 100px;

                svg {
                    margin: -25px 0;
                }
            }
        }

        .hero-content {
            width: 475px;
            max-width: 70%;

            a {
                position: relative;
                z-index: 1;
            }

            .hero-banner-subheading {
                margin: 25px 0 0 0;
                font-size: 16px;
                line-height: 1.5;
            }

            &.thought-bubble {
                padding: @margin-large * 2 @margin-super;
                width: 700px;
                align-self: center;
                border-radius: @border-radius-xl;
                border-bottom-right-radius: 0;
            }

            &.align-right.thought-bubble {
                border-radius: @border-radius-xl;
                border-bottom-left-radius: 0;
            }

            &.align-center {
                text-align: center;

                .hero-icon {
                    margin-left: auto;
                    margin-right: auto;
                }

                &.thought-bubble {
                    border-radius: @border-radius-xl;
                }
            }
        }
    }
}