﻿@import '../framework/imports.less';

.component-base {
    text-align: center;
}

section.component-background {
    background-size: cover;
    background-position: 50% 50%;

    @media (min-width: @screen-sm) {
        padding-top: @margin-large;
        padding-bottom: @margin-large;
    }
}

.component-base-header {
    text-align: center;
    margin-bottom: 30px;
    display: inline-block;

    .component-background & {
        background: @color-white;
        margin-left: @grid-gutter-width;
        margin-right: @grid-gutter-width;
        padding: 20px 30px;

        @media (min-width: @screen-sm) {
            margin-left: 0;
            margin-right: 0;
            margin-bottom: @margin-large;
        }
    }
}

.component-base-heading {
    font-size: @font-size-h3;
    margin: 0;
    line-height: 1em;

    @media (min-width: @screen-sm) {
        font-size: @font-size-h2;
    }
}

.component-base-subhead {
    margin: @margin-small 0 0 0;
    color: @gray-medium;
}

.component-container {
    max-width: @masthead-max-width;
    background: @color-white;

    .component-background & {
        margin: 0 @grid-gutter-width;
    }

    @media (min-width: @screen-sm) {
        width: auto;
    }

    @media (min-width: @navigation-breakpoint-large) {
        width: auto;
        margin-left: auto;
        margin-right: auto;
        max-width: @masthead-max-width;

        .component-background & {
            margin: 0 auto;
        }
    }
}

.btn-component-more {
    margin-top: 20px;

    @media (min-width: @screen-sm) {
        .component-background & {
            margin-top: @margin-large;
        }
    }
}

.cms-element > .container:not(.component-product-container),
.cms-element div[data-cms-element-content] > .container:not(.component-product-container) {
    .component-margin();
}

div.cms-element > .cms-html-block,
div.cms-element > div[data-cms-element-content] > .cms-html-block.container {
    h1,
    h2,
    h3,
    h4,
    h5 {
        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    p:first-child {
        margin-bottom: 0;
    }

    p:last-child {
        margin-bottom: 0;
    }
}

.guides-container {
    .single-column .cms-layout:first-of-type .cms-element .cms-html-block.container {
        margin-top: @margin-small;
    }

    max-width: 100%;
    margin-top: 50px;

    .hr {
        display: none;
    }
}