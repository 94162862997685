.evo-svg-animation {
	overflow: visible !important;
}

.animated-icon {
	display: block;
}

// ICON HUB

.icon-hub.animated-icon {
	polygon {
	    opacity: 0;
	    transition: opacity 0.3s ease, transform 0.3s cubic-bezier(.59,.68,.44,.94);
	}
	  
	#polygon-1 {
	    transform: translateX(-35%);
	}

	#polygon-2 {
	    transform: translateX(-25%);
	}
	
	#polygon-3 {
	    transform: translateX(-15%);
	}
	  
	#polygon-4 {
	    transform: translateX(35%);
	}
	  
	#polygon-5 {
	    transform: translateX(25%);
	}
	  
	#polygon-6 {
	    transform: translateX(15%);
	}
	  
	&.animate {
	    polygon {
	      	opacity: 1 !important;
	      	transform: translateX(0) !important;
	      	transition: opacity 0.2s ease, transform 0.3s cubic-bezier(.59,.68,.44,.94);
	    }
	}
}

// ICON AMBASSADORS

.icon-ambassadors.animated-icon {
	g {
	    opacity: 0;
	    transition: opacity 0.3s ease, transform 0.3s cubic-bezier(.59,.68,.44,.94);
	}
	  
	#rect1 {
	    transform: translateX(-15%);
	}
	  
	#rect2 {
	    transform: translateX(15%);
	}
	  
	&.animate {
	    g {
	      	opacity: 1 !important;
	      	transform: translateX(0) !important;
	      	transition: opacity 0.2s ease, transform 0.3s cubic-bezier(.59,.68,.44,.94);
	      
	      	&#circle {
	        	transition: opacity 0.3s ease;
	      	}
	    }
	}
}

// ICON EVENTS

.icon-events.animated-icon {
	rect {
	    opacity: 0;
	    transition: opacity 0.3s ease, transform 0.3s cubic-bezier(.59,.68,.44,.94);
	}
	  
	#rect1 {
	    transform: translateX(-15%);
	}
	  
	#rect3 {
	    transform: translateX(15%);
	}
	  
	&.animate {
	    rect {
	      	opacity: 1 !important;
	      	transform: translateX(0) !important;
	      	transition: opacity 0.2s ease, transform 0.3s cubic-bezier(.59,.68,.44,.94);
	      
	      	&#rect2 {
	        	transition: opacity 0.3s ease;
	      	}
	    }
	}
}

// ICON REST

.icon-rest.animated-icon {
	circle {
	    opacity: 0;
	    transition: opacity 0.3s ease, transform 0.3s cubic-bezier(.59,.68,.44,.94);
	}
	  
	#circle1 {
	    transform: translateX(-15%);
	}
	  
	#circle3 {
	    transform: translateX(15%);
	}
	  
	&.animate {
	    circle {
	      	opacity: 1 !important;
	      	transform: translateX(0) !important;
	      	transition: opacity 0.2s ease, transform 0.3s cubic-bezier(.59,.68,.44,.94);
	      
	      	&#circle2 {
	        	transition: opacity 0.3s ease;
	      	}
	    }
	}
}

// ICON GUIDES

.icon-guides.animated-icon {
	polygon {
	    opacity: 0;
	    transition: opacity 0.3s ease, transform 0.3s cubic-bezier(.59,.68,.44,.94);
	}
	  
	#polygon1 {
	    transform: translateX(-35%);
	}
	  
	#polygon2 {
	    transform: translateX(-25%);
	}
	  
	#polygon3 {
	    transform: translateX(-15%);
	}
	  
	&.animate {
	    polygon {
	      	opacity: 1 !important;
	      	transform: translateX(0) !important;
	      	transition: opacity 0.2s ease, transform 0.3s cubic-bezier(.59,.68,.44,.94);
	    }
	}
}

// ICON IMPACT

.icon-impact.animated-icon {
	polygon,
	circle {
	    opacity: 0;
	    transition: opacity 0.3s ease, transform 0.3s cubic-bezier(.59,.68,.44,.94);
	    transform-origin: 50% 50%;
	}
	  
	#polygon1 {
	    transform: translateX(-20%) scale(0.75);
	}
	  
	#circle {
	    transform: scale(1.25);
	}
	  
	#polygon2 {
	    transform: translateX(20%) scale(0.75);
	}
	  
	&.animate {
	    polygon,
	    circle {
	      	opacity: 1 !important;
	      	transform: translate(0) scale(1) !important;
	      	transition: opacity 0.2s ease, transform 0.3s cubic-bezier(.59,.68,.44,.94);
	    }
	}
}

// ICON LOCATIONS

.icon-locations.animated-icon {
	polygon,
	circle {
	    opacity: 0;
	    transition: opacity 0.3s ease, transform 0.3s cubic-bezier(.59,.68,.44,.94);
	}
	  
	circle {
	    transform: translateY(-12%);
	}
	  
	polygon {
	    transform: translateY(12%);
	}
	  
	&.animate {
	    polygon,
	    circle {
	      	opacity: 1 !important;
	      	transform: translateY(0) !important;
	      	transition: opacity 0.2s ease, transform 0.3s cubic-bezier(.59,.68,.44,.94);
	    }
	}
}

// ICON ACADEMY

.icon-academy.animated-icon {
	g {
	    opacity: 0;
	    transition: opacity 0.3s ease, transform 0.3s cubic-bezier(.59,.68,.44,.94);
	}
	  
	#rect1 {
	    transform: translate(-15%, -15%);
	}
	  
	#rect2 {
	    transform: translate(-15%, 15%);
	}
	  
	#rect3 {
	    transform: translate(15%, 15%);
	}
	  
	#rect4 {
	    transform: translate(15%, -15%);
	}
	  
	&.animate {
	    g {
	      	opacity: 1 !important;
	      	transform: translate(0) !important;
	      	transition: opacity 0.2s ease, transform 0.3s cubic-bezier(.59,.68,.44,.94);
	    }
	}
}