﻿@import '../framework/imports.less';
@background-banner-height-mobile: 40vh;
@background-banner-height: 40vw;

.background-banner {
    height: @background-banner-height-mobile;
    width: 100%;
    overflow: hidden;
    position: relative;
    z-index: @background-banner-z-index + 1;
    margin-bottom: 0;

    @media (min-width: @screen-sm) {
        height: @background-banner-height;
    }

    &.background-banner-overlap + .container {
        background: @color-white;

        @media (min-width: @screen-sm) {
            margin-top: @component-margin-large * -1;
        }
    }

    & + div {
        position: relative;
        background-color: @color-white;
        z-index: @background-banner-z-index + 1;
    }

    &:after {
        position: absolute;
        top: 0;
        left: 0;
        margin: 0;
        z-index: @background-banner-shadow-z-index;
    }
}

.background-banner-image {
    height: @background-banner-height-mobile;

    @media (min-width: @screen-sm) {
        height: @background-banner-height;
    }

    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: @background-banner-z-index;
}

.background-banner-container {
    background-color: @color-white;
    margin-top: -@margin-base * 2;

    @media (max-width: @screen-sm-max) {
        .intro-text-half {
            margin-top: @component-margin-large;
        }
    }

    @media (min-width: @screen-md) {
        margin-top: -@component-margin-large;
    }
}