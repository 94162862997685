﻿@import '../framework/imports.less';

@testimonial-height: 380px;

.testimonial-wrapper {
    height: auto;
    min-height: 380px;
    width: 100%;
    background-color: @color-gray-lighter;
    padding: @component-margin-large 0;

    @media (min-width: @screen-sm) {
        padding: @component-margin-large + 30 0;
    }
}

.testimonial-container {
    position: relative;
    height: 100%;
    width: 100%;
}

.testimonial-stage {
    text-align: center;
    height: 100%;
    position: relative;

    blockquote {
        &:before {
            color: @color-white;
        }
    }
}

.testimonial-slide {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    .flex-display;
    .justify-content(center);
    .align-items(center);
    opacity: 0;
    .transition(opacity 0.3s ease-out);

    &:first-child {
        position: relative;
    }

    &.active {
        opacity: 1;
    }
}

.testimonial-content {
    @media (max-width: @screen-xs-max) {
        blockquote {
            padding: 0 26px;
            font-size: @font-size-intro;

            &:before {
                font-size: 200px;
            }
        }
    }
}

.testimonial-author {
    font-size: @font-size-intro;
    text-transform: uppercase;
    margin-top: 20px;

    @media (min-width: @screen-sm) {
        margin-top: @component-margin-base;
    }
}

.testimonial-details {
    font-size: @font-size-intro;
    color: @color-gray-dark;
    font-style: italic;
}