﻿@import '../framework/imports.less';

@highlight-marker-size: 45px;

.highlight-item {
    .make-xs-column(12);
    .make-sm-column(8);
    .make-sm-column-offset(2);
    .make-md-column(6);
    .make-md-column-offset(3);
    margin-bottom: @margin-base;
    .flex-display(flex);
    .align-items(baseline);

    p {
        margin-bottom: 5px;
    }

    h5 {
        margin-top: 5px;
    }

    .highlight-marker {
        background: @gray-dark;
        border-radius: 50%;
        color: @color-white;
        display: inline-block;
        font-size: 14px;
        font-weight: bold;
        height: @highlight-marker-size;
        line-height: @highlight-marker-size;
        margin-right: 1em;
        margin-top: 15px;
        min-width: @highlight-marker-size;
        text-align: center;
        width: @highlight-marker-size;
    }
}