﻿@import '../framework/imports.less';

.component-featured-products {
    .component-margin();

    .component-container {
        padding: 0 @grid-gutter-width;

        .component-background & {
            padding: 20px @grid-gutter-width;
        }

        @media (min-width: @screen-sm) {
            display: flex;
            align-items: flex-end;
        }
    }

    &.component-background .component-container {
        padding: 20px @grid-gutter-width;
    }
}

.component-featured-products {
    .product-thumb {
        width: 34vw;
        padding: 10px;
        font-size: 13px;
        flex-shrink: 0;

        @media (min-width: @screen-xs) {
            width: 25vw;
        }

        @media (min-width: @screen-sm) {
            padding: 15px;
            width: 33.333%;
        }

        @media (min-width: @screen-lg) {
            padding: 30px;
        }
    }

    .product-thumb-image {
        max-width: 100%;
        width: 220px;
    }

    .product-thumb-details {
        margin-bottom: 0;
        border: 0;
        padding-bottom: 0;
    }

    .product-thumb-price {
        margin-bottom: 0;
    }

    .product-thumb-reviews,
    .product-thumb-swatches,
    .compare-label {
        display: none;
    }
}

.product-thumb-featured {
    padding: 10px 8vw 20px 8vw;

    @media (min-width: @screen-sm) {
        width: 40%;
        padding: 15px;
    }

    @media (min-width: @screen-lg) {
        padding: 30px;
    }
}

.product-thumb-featured-image {
    max-width: 100%;
}

.component-featured-products-results {
    margin-left: @grid-gutter-width * -1;
    margin-right: @grid-gutter-width * -1;
    position: relative;

    @media (min-width: @screen-sm) {
        width: 60%;
        margin-left: 0;
        margin-right: 0;
    }

    &:after,
    &:before {
        content: '';
        position: absolute;
        z-index: 10;
        width: 30px;
        height: 100%;
        left: 0;
        top: 0;
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&1+0,0+100 */
        background: -moz-linear-gradient(left, rgba(255,255,255,1) 3%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left, rgba(255,255,255,1) 3%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, rgba(255,255,255,1) 3%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 */
        @media (min-width: @screen-sm) {
            display: none;
        }
    }

    &:after {
        right: 0;
        left: auto;
        transform: rotate(180deg);
    }
}

.component-featured-products-overflow {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    padding-left: @grid-gutter-width;
    padding-right: @grid-gutter-width;

    @media (min-width: @screen-sm) {
        flex-wrap: wrap;
        padding-left: 0;
        padding-right: 0;
    }
}