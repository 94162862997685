.product-finder-container {
	background-color: #f8f4f4;
}

.product-finder-form {
	margin: auto;
	max-width: 600px;
	padding: 50px 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 30px;

	.btn {
		align-self: center;
	}
	
	.component-header {
		margin: 0;
		
		&:empty {
			display: none;
		}
	}
	
	.form-group {
		margin-bottom: 0;
	}
}

.select-inputs {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	gap: 30px 0;
	justify-items: center;

	.product-finder-select {
		box-sizing: border-box;
		min-width: 250px;
	}
}

