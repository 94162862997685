﻿@import '../framework/imports.less';

@accordion-breakpoint: @screen-sm;

//ACCORDION COMPONENT
.accordion-head {
    position: relative;
    cursor: pointer;
    margin: 0;
    line-height: 1;
    font-size: @font-size-intro;
    text-transform: uppercase;
    line-height: 1;
    font-weight: bold;
    padding-top: @component-margin-small;
    padding-bottom: @component-margin-small;

    &:before, &:after {
        content: "";
        position: absolute;
        top: 50%;
        display: block;
        width: 1em;
        height: 2px;
        background-color: @color-black;
        transition: all 0.3s ease-out;
    }

    &:before {
        right: @margin-medium;
        .rotate(0deg);
    }

    &:after {
        right: @margin-medium;
        .rotate(90deg);
    }

    &.expanded {
        &:before {
            .rotate(180deg);
        }

        &:after {
            .rotate(180deg);
        }
    }

    &.accordion-mobile {
        @media (min-width: @accordion-breakpoint) {
            cursor: default;

            &:before, &:after {
                display: none;
            }
        }
    }
}

.accordion-body {
    display: none;
}

.accordion-mobile {
    @media (min-width: @accordion-breakpoint) {
        .accordion-head {
            display: none;
        }

        .accordion-body {
            display: block;
        }
    }
}

.accordion-mobile {
    @media (min-width: @accordion-breakpoint) {
        .accordion-head {
            display: none;
        }

        .accordion-body {
            display: block;
        }
    }
}

.accordion-desktop {
    .accordion-head {
        display: block;
    }

    .accordion-body {
        display: none;
    }
}

.desktop-accordion-header {
    display: none;

    @media (min-width: @accordion-breakpoint) {
        display: block;
    }
}