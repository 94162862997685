.video-series {
	.navbar + & {
		margin-top: @spacing-48;

	    @media(min-width: @screen-sm) {
	        margin-top: @gutter * 3;
	    }
	}
}

.navbar-carousel {
	.navbar-item .navbar-icon.custom-color svg {
		circle,
		polygon,
		rect {
			fill: inherit !important;
		}
	}
}

.video-series-slide {
	@media(max-width: @screen-md) {
		.multi-column-container {
			display: flex;
			flex-direction: column;

			.html-column {
				width: 100% !important;

				&.media {
					order: -1;
					margin-bottom: @spacing-32;
					margin-top: 0;

					& + .html-column {
						margin-top: 0;
					}
				}
			}
		}
	}

	.video-wrap {
		width: 100%;
		padding-top:56.25%;
        position: relative;
        background-size:cover;
        background-position:center;
        cursor: pointer;

        iframe {
	        position: absolute;
	        left: 0;
	        bottom: 0;
	        height: 100%;
	        width: 100%;
	        display: none;
	    }

	    &.video-active iframe { 
	    	display: block;
	    	z-index: 2; 
	    }

	    .btn-video-play {
	    	position:absolute;
	    	top: 50%;
	    	left: 50%;
	    	transform: translate(-50%, -50%);
	    	appearance: none;
	    	-webkit-appearance: none;
	    	border: none;
	    	border-radius: 100%;
	    	width: 72px;
	    	height: 72px;
	    	color: @color-sage;
	    	font-size: 30px;
	    	display: flex;
	        align-items: center;
	        justify-content: center;
	        padding: 0;

	    	@media(min-width: @screen-lg) {
	    		width: 144px;
		    	height: 144px;
		    	font-size: 60px;
	    	}
	    }
	}

	.media {
		@media(min-width: @screen-lg) {
			align-self: center;
		}
	}
}