@import '../framework/imports.less';

.navbar {
	width: 100%;
	padding: @margin-base @gutter-mobile;
	text-align: center;

	.navbar-title {
		margin: 0 0 @margin-small 0;
	}

	@media (min-width: @screen-sm) {
		padding: @margin-medium * 2 @gutter * 3;

		.navbar-title {
			margin: 0 0 @margin-base 0;
		}
	}
}

.navbar-navigation {
	ul {
		list-style-type: none;
		padding: 0;
		margin: 0;
	}
}

.navbar-item {
	display: inline-block;
	position: relative;

	.navbar-icon {
		position: absolute;
		width: 50px;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		opacity: 0.45;
    	mix-blend-mode: multiply;

    	&.icon-ambassadors svg {
    		rect,
    		polygon,
    		circle {
    			fill: @color-sunny !important;
    		}
    	}

    	&.icon-impact svg {
    		rect,
    		polygon,
    		circle {
    			fill: @color-tomato !important;
    		}
    	}

    	&.icon-locations svg {
    		rect,
    		polygon,
    		circle {
    			fill: @color-sage !important;
    		}
    	}

    	&.icon-academy svg {
    		rect,
    		polygon,
    		circle {
    			fill: @color-sky-blue !important;
    		}
    	}

    	&.icon-guides svg {
    		rect,
    		polygon,
    		circle {
    			fill: @color-tomato !important;
    		}
    	}

    	&.icon-rest svg {
    		rect,
    		polygon,
    		circle {
    			fill: @color-sage !important;
    		}
    	}

    	&.icon-events svg {
    		rect,
    		polygon,
    		circle {
    			fill: @color-sunny !important;
    		}
    	}

    	&.icon-hub svg {
    		rect,
    		polygon,
    		circle {
    			fill: @color-sky-blue !important;
    		}
    	}
	}

	a.heading-5 {
		.rem(14);
		padding: @padding-large-vertical @padding-xs-horizontal;
		position: relative;
		z-index: 1;
		display: block;
		margin: 0;
		transition: color 0.3s ease;

		&:hover {
			color: #000000;
			text-decoration: none;
		}

		&:focus {
			color: #000000;
			text-decoration: none;
		}
	}

	&:hover, &.current-item {
		.heading-4 {
			color: #000000;
		}
	}

	@media (min-width: @screen-sm) {
		.navbar-icon {
			width: 96px;
		}

		a.heading-5 {
			.rem(24);
			padding: @padding-large-vertical @margin-base;
		}
	}
}